import {
  UserOutlined,
  RadarChartOutlined,
  DollarCircleOutlined,
  BranchesOutlined,
  SplitCellsOutlined,
  BankOutlined,
  ExceptionOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";

const getNuv = ({isAdmin}) => {
  if (isAdmin) {
    return adminNav;
  } else return otherNav;
};

const adminNav = [
  {
    key: "1",
    icon: <FileSyncOutlined />,
    label: "Счета на оплату",
    url: "/bills-for-payment",
  },
  {
    key: "2",
    icon: <BankOutlined />,
    label: "История счетов",
    url: "/bills-history",
  },
  {
    key: "3",
    icon: <ExceptionOutlined />,
    label: "Мои счета",
    url: "/my-bill",
  },
  {
    key: "4",
    icon: <UserOutlined />,
    label: "Управление",
    children: [
      {
        key: "5",
        icon: <UserOutlined />,
        label: "Пользователи",
        url: "/users",
      },
      {
        key: "6",
        icon: <RadarChartOutlined />,
        label: "Фирмы",
        url: "/firms",
      },
      {
        key: "7",
        icon: <RadarChartOutlined />,
        label: "Сторонние организации",
        url: "/side-firms",
      },
      {
        key: "8",
        icon: <DollarCircleOutlined />,
        label: "Статьи расходов",
        url: "/expenses",
      },
      {
        key: "9",
        icon: <BranchesOutlined />,
        label: "Цепочки согласований",
        url: "/chaotic-chain",
      },
      {
        key: "10",
        icon: <SplitCellsOutlined />,
        label: "Подразделения",
        url: "/states",
      },
    ],
  },
];

const otherNav = [
  {
    key: "1",
    icon: <ExceptionOutlined />,
    label: "Мои счета",
    url: "/my-bill",
  },
  {
    key: "2",
    icon: <FileSyncOutlined />,
    label: "Счета на оплату",
    url: "/bills-for-payment",
  },
  {
    key: "3",
    icon: <BankOutlined />,
    label: "История счетов",
    url: "/bills-history",
  },
];

export default getNuv;
