const HEADERS_JSON = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const request = async (url, options = {}, notJson = false) => {
  const params = {...options};
  params.headers = {
    ...getHeaders(),
    ...params.headers,
    ...(options && options.headers ? options.headers : {}),
  };

  if (params.body) {
    params.body = JSON.stringify(params.body);
  }

  return fetch(`/api/${url}`, params).then(async (response) => {
    if (response.status === 200) {
      return {
        status: response.status,
        data: notJson ? response : await response.json(),
      };
    } else if (response.status === 401) {
      localStorage.removeItem("token");
      window.location.replace("/login");
    } else {
      return response;
    }
  });
};

function getHeaders() {
  const token = localStorage.getItem("token");
  return {
    ...HEADERS_JSON,
    Authorization: token,
  };
}

export default request;
