import React, {useEffect, useState} from "react";
import {Bill} from "@components/Bill/Bill";
import api from "@modules/api";
import {Result, Button, Space} from "antd";
import {Filters} from "@components/BillFilters/Filters";
import {SearchOutlined} from "@ant-design/icons";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import "./style.css";

export const BillsForPayment = ({toggleLoading}) => {
  const [bills, setBills] = useState([]);
  const [selectMode, setSelectMode] = useState(false);
  const [selected, setSelected] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const [firms, setFirms] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [downloadPending, setDownloadPending] = useState(false);
  const [filters, setFilters] = useState({
    mode: "forPass",
    firm: undefined,
    org: undefined,
    exp: undefined,
    author: undefined,
    create: undefined,
    nearestDate: undefined,
    payDate: undefined,
    type: undefined,
    id: "",
    priority: undefined,
    paid: false,
    confirmation: false,
    canceled: false,
  });
  const updateData = (filters) => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });
    getFiltredBills(newParams);
    setSelectMode(false);
    setSelected({});
  };

  const setFilterByTitle = (title, data) => {
    const updatedFilters = {};

    if (Array.isArray(title) && Array.isArray(data)) {
      title.forEach((value, index) => {
        updatedFilters[value] = data[index];
      });
    } else {
      updatedFilters[title] = data;
    }

    const newFilters = {...filters, ...updatedFilters};
    setFilters(newFilters);
  };

  const setOrgs = (ids) => {
    if (!ids.length) {
      setFilters({...filters, org: undefined, type: undefined});
      return;
    }
    const types = [];
    ids.forEach((id) => {
      const org = organizations.find((org) => org.id === id);
      if (org) {
        types.push(org.typeOrganization);
      }
    });
    const newFilters = {...filters, org: ids, type: types};
    setFilters(newFilters);
  };

  const idSearch = () => {
    const newParams = new URLSearchParams();
    newParams.set("mode", filters.mode);
    newParams.set("id", filters.id);
    getFiltredBills(newParams);
  };

  const applyFilters = () => {
    updateData(filters);
  };

  const handleSelectBill = (id) => {
    if (String(id) in selected) {
      setSelected((prev) => {
        const newObj = {...prev};
        delete newObj[String(id)];
        return {...newObj};
      });
    } else {
      setSelected((prev) => ({...prev, [String(id)]: true}));
    }
  };

  const fetchBills = async (params) => {
    const response = await api(`bill/payment?${params}`);
    if (response.status === 200) {
      setBills(response.data);
      if (!response.data.length) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    }
  };

  const getFiltredBills = async (params) => {
    toggleLoading();
    await fetchBills(params);
    toggleLoading();
  };

  const downloadBills = async () => {
    setDownloadPending(true);
    await api(
      `bill/download`,
      {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: Object.keys(selected),
      },
      true
    )
      .then((response) => response.data.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "bills.zip";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
    setDownloadPending(false);
  };

  const fetchFilters = async () => {
    const response = await api(`bill/filters`);
    if (response) {
      if (response?.status) {
        const [firmsData, sideFirmsData, statesData, expensesData] =
          response.data;
        if (firmsData.status) {
          setFirms(
            firmsData.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
          );
        }
        if (expensesData.status) {
          setExpenses(expensesData.data);
        }
        const organizationData = [
          ...sideFirmsData.data?.map((val) => ({
            ...val,
            typeOrganization: "organization",
          })),
          ...statesData.data?.map((val) => ({
            ...val,
            name: val.title,
            typeOrganization: "state",
          })),
        ].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setOrganizations(organizationData);
      }
    }
  };

  const getData = async () => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });

    fetchBills(newParams);
  };

  const fetchInitialData = async () => {
    await Promise.all([fetchFilters(), getData()]);
  };

  useEffect(() => {
    (async () => {
      await fetchInitialData();
      toggleLoading();
    })();
  }, []);

  const editRequestCallback = (id) => {
    const index = bills.findIndex((val) => {
      return val.id === id;
    });
    setBills([...bills.slice(0, index), ...bills.slice(index + 1)]);
  };

  const removeBill = async () => {
    updateData(filters);
  };

  const resetFilters = (title, data) => {
    const updatedFilters = {};

    if (Array.isArray(title) && Array.isArray(data)) {
      title.forEach((value, index) => {
        updatedFilters[value] = data[index];
      });
    } else {
      updatedFilters[title] = data;
    }
    const newFilters = {
      ...filters,
      firm: undefined,
      org: undefined,
      type: undefined,
      exp: undefined,
      author: undefined,
      create: undefined,
      nearestDate: undefined,
      payDate: undefined,
      id: "",
      priority: undefined,
      paid: false,
      confirmation: false,
      canceled: false,
      ...updatedFilters,
    };
    setFilters(newFilters);

    updateData(newFilters);
  };

  return (
    <>
      <Space style={{marginBottom: "15px"}} direction="horizontal">
        {selectMode ? (
          <>
            <Space>
              <Button
                danger
                onClick={() => {
                  setSelectMode(false);
                  setSelected({});
                }}
              >
                Отменить выбор
              </Button>
            </Space>
            <Space>
              <Button
                onClick={() => {
                  const newObj = {};
                  bills.forEach((val) => {
                    newObj[val.id] = true;
                  });
                  setSelected(newObj);
                }}
              >
                Выбрать все
              </Button>
            </Space>
            <Space>
              <Button
                disabled={!Object.keys(selected).length}
                loading={downloadPending}
                type="primary"
                onClick={downloadBills}
              >
                Скачать
              </Button>
            </Space>
          </>
        ) : (
          <Space>
            <Button onClick={() => setSelectMode(true)}>Выбрать счета</Button>
          </Space>
        )}
      </Space>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "20px",
          position: "relative",
        }}
      >
        <div style={{flex: "1 1 auto"}}>
          <TransitionGroup>
            {bills.map((el) => (
              <CSSTransition
                key={
                  el.id +
                  "_" +
                  el.billConfirmations.findLast((val) => val.status === "new")
                    ?.user.id
                }
                timeout={500}
                classNames="item"
              >
                <Bill
                  selectMode={selectMode}
                  selected={selected[el.id]}
                  onSelect={handleSelectBill}
                  editRequestCallback={editRequestCallback}
                  bill={el}
                  forPayment={true}
                  showAuthor={true}
                  toggleLoading={toggleLoading}
                  removeBill={removeBill}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
          {empty && (
            <Result
              className="no-bills"
              icon={<SearchOutlined />}
              status="info"
              title="Согласований не найдено"
            />
          )}
        </div>
        <div>
          <Filters
            mode={"payment"}
            applyFilters={applyFilters}
            passed={false}
            filters={filters}
            firms={firms}
            orgs={organizations}
            expenses={expenses}
            authors={authors}
            setAuthorOptions={setAuthors}
            setOrgs={setOrgs}
            idSearch={idSearch}
            resetFilters={resetFilters}
            setFilterByTitle={setFilterByTitle}
          />
        </div>
      </div>
    </>
  );
};
